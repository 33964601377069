import React, { useEffect, useRef, useState } from 'react';
import MainHeader from '../../Components/Header/Header';
import { auth } from '../../Database/config';
import { useNavigate } from 'react-router-dom';
import { dbc } from '../../Database/fundi';
import './Fundi.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export default function Fundi() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/')
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const collectionRef = dbc.collection('users'); // Replace with your collection name

    collectionRef.get().then((querySnapshot) => {
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });
      setData(fetchedData);
      setLoading(false);
    });
  }, []);

  const confirm = (id) => {
    confirmDialog({
      message: 'Are you sure you want to proceed? Once deleted, the record cannot be recovered',
      header: 'Delete Record',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      acceptClassName: 'bg-red-500 px-2 py-1 text-white font-semibold ml-4',
      rejectClassName: 'bg-gray-300 px-2 py-1 text-black font-semibold',
      acceptLabel: 'Yes, Delete',
      rejectLabel: 'Cancel',
      accept: () => deletePerson(id),
      reject
    });
  }

  const reject = () => {
    toast.current.show({ severity: 'info', summary: 'Action cancelled', detail: 'You have not deleted user record', life: 3000 });
  }

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    setGlobalFilterValue('');
  };

  const showButtons = (rowData) => {
    return (
      <div className='flex flex-row justify-between'>
        <EyeIcon onClick={() => redirectToView(rowData.id)} className='w-4 h-4 text-gray-500 cursor-pointer' />
        <PencilIcon onClick={() => redirectToUpdate(rowData.id)} className='w-4 h-4 text-green-500 cursor-pointer' />
        <TrashIcon onClick={() => confirm(rowData.id)} className='w-4 h-4 text-red-500 cursor-pointer' />
      </div>
    );
  };

  const handleVerify = async (id) => {
    try {
      const DocRef = dbc.collection("users").doc(id);
      await DocRef.update({
        verified: true
      });
      console.log("Document successfully updated!");
      // Refresh the page
      window.location.reload();
      // Show alert
      alert("Update successful!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }


  const verifyButton = (rowData) => {
    return (
      <div className='flex flex-row justify-between'>
        <Button onClick={ () => handleVerify(rowData.id)}>Verify</Button>
      </div>
    );
  };

  const redirectToUpdate = (id) => {
    navigate(`/fundi/update/${id}`);
  }

  const redirectToView = (id) => {
    navigate(`/fundi/${id}`);
  }

  const deletePerson = async (id) => {
    try {
      await dbc.collection('users').doc(id).delete();
      const updatedData = data.filter((item) => item.id !== id);
      setData(updatedData);
      toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have deleted user successfully', life: 3000 });
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error occurred. Please try again later.', life: 3000 });
    }
  };


  const header = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-2">
        <div>
          <h1 className='font-bold text-xl'>
            <span style={{ color: "#FF0000" }}>Hustle Kenya</span> Agents ({data.length})
          </h1>
        </div>
        <div className='flex flex-row gap-2 items-center'>
          <div className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText className='px-4 py-2 border border-gray-800 font-semibold' value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
          </div>
          <Button type="button" className='bg-transparent border border-blue-400 p-2 text-blue-400 hover:border-blue-600 hover:text-blue-600' label="Clear Filter" outlined onClick={clearFilter} />
        </div>
      </div>
    );
  };


  return (
    <div className='flex flex-col'>
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <MainHeader className="mb-20" />
      <div className='flex flex-col gap-4 mt-24 px-12 pb-20'>
        <DataTable className='text-sm'
          value={data}
          stripedRows
          paginator
          filters={filters}
          globalFilterFields={['firstName', 'lastName', 'phoneNumber', 'profession', 'idNumber', 'verified']}
          header={header}
          rows={10}
          emptyMessage="No agents found."
          loading={loading}
          rowsPerPageOptions={[10, 25, 50]}
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column className='border' header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1}></Column>
          <Column className='border' header="Name" body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}></Column>
          <Column className='border' header="ID Number" body={(rowData) => `${rowData.idNumber}`}></Column>
          <Column className='border' header="Phone Number" body={(rowData) => `${rowData.phoneNumber}`}></Column>
          <Column className='border' header="Profession" body={(rowData) => `${rowData.profession}`}></Column>
          <Column className='border' header="Email" body={(rowData) => `${rowData.email}`}></Column>
          <Column className='border' header="Verified" body={(rowData) => `${rowData.verified}`}></Column>
          <Column className='border' header="Verify" body={verifyButton}></Column>
          <Column className='border' header="Actions" body={showButtons}></Column>
        </DataTable>
      </div>
    </div>
  );
}