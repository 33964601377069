import React, { useState, useRef, useEffect } from 'react';
import './FundiSignup2.css'
import MainHeader from '../../../Components/Header/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authc, dbc, storageRefc } from '../../../Database/fundi';
import { auth } from '../../../Database/config';
import countiesData from '../../../data/county.json';
import { Toast } from 'primereact/toast';

export default function FundiSignup2({ }) {
    const location = useLocation();
    const navigate = useNavigate();

    const firstname = location.state.firstname;
    const lastname = location.state.lastname;
    const professionalbody = location.state.professionalbody;
    const profession = location.state.profession;

    const [professionalbodyno, setProfessionalBodyNo] = useState("");
    const [identificationnumber, setIdentificationNumber] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState('');
    const [countylocation, setCountyLocation] = useState("");
    const [goodconductrefno, setGoodConductRefNo] = useState("");
    const [counties, setCounties] = useState([]);
    const [portfoliourl, setPortfolioUrl] = useState("");
    const [profileimage, setProfileImage] = useState(null);
    const [frontofid, setFrontOfId] = useState(null);
    const [backofid, setBackOfId] = useState(null);
    const [copyofgoodconduct, setCopyOfGoodConduct] = useState(null);
    const [copyofcv, setCopyOfCV] = useState(null);

    const toast = useRef(null);


    useEffect(() => {
        // Load counties from the imported JSON data
        setCounties(countiesData.County);
    }, []);

    const handleCountyChange = (event) => {
        setCountyLocation(event.target.value);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
    };

    const handleCVChange = (e) => {
        const file = e.target.files[0];
        setCopyOfCV(file);
    }

    const handleGoodConductChange = (e) => {
        const file = e.target.files[0];
        setCopyOfGoodConduct(file);
    }

    const handleFrontIDChange = (e) => {
        const file = e.target.files[0];
        setFrontOfId(file);
    }

    const handleBackIDChange = (e) => {
        const file = e.target.files[0];
        setBackOfId(file);
    }

    const uploadImageToStorage = async () => {
        if (profileimage) {
            const imageRef = storageRefc.child(`profileImages/${profileimage.name}`);
            await imageRef.put(profileimage);
            const imageUrl = await imageRef.getDownloadURL();
            return imageUrl;
        }
        return null;
    };

    // const uploadCVToStorage = async () => {
    //     if (copyofcv) {
    //         const cvRef = storageRefc.child(`CVs/${copyofcv.name}`);
    //         await cvRef.put(copyofcv);
    //         const CVurl = await cvRef.getDownloadURL();
    //         return CVurl;
    //     }
    //     return '';
    // }

    // const uploadGoodConduct = async () => {
    //     if (copyofgoodconduct) {
    //         const goodConductRef = storageRefc.child(`GoodConducts/${copyofgoodconduct.name}`);
    //         await goodConductRef.put(copyofgoodconduct);
    //         const goodConductUrl = await goodConductRef.getDownloadURL();
    //         return goodConductUrl;
    //     }
    //     return '';
    // }

    // const uploadFrontOfID = async () => {
    //     if (frontofid) {
    //         const frontCardRef = storageRefc.child(`FrontId/${frontofid.name}`);
    //         await frontCardRef.put(frontofid);
    //         const frontCardUrl = await frontCardRef.getDownloadURL();
    //         return frontCardUrl;
    //     }
    //     return '';
    // }

    // const uploadBackOfID = async () => {
    //     if (frontofid) {
    //         const frontCardRef = storageRefc.child(`BackId/${frontofid.name}`);
    //         await frontCardRef.put(frontofid);
    //         const frontCardUrl = await frontCardRef.getDownloadURL();
    //         return frontCardUrl;
    //     }
    //     return '';
    // }


    const handleSignUp = async () => {

        if (
            email !== '' &&
            identificationnumber !== '' &&
            phonenumber !== '' &&
            countylocation !== '' &&
            goodconductrefno !== '' &&
            firstname !== '' &&
            lastname !== '' &&
            professionalbody !== '' &&
            profession !== '' &&
            profileimage !== ''
        ) {
            const imageUrl = await uploadImageToStorage();
            // const cvUrl = await uploadCVToStorage();
            // const goodConductUrl = await uploadGoodConduct();
            // const idFrontUrl = await uploadFrontOfID();
            // const idBackUrl = await uploadBackOfID();

            authc
                .createUserWithEmailAndPassword(email, identificationnumber)
                .then(userCredentials => {
                    const user = userCredentials.user;
                    dbc.collection('FundiAppUsers').doc(user.uid).set({
                        firstname,
                        lastname,
                        professionalbody,
                        profession,
                        professionalbodyno,
                        identificationnumber,
                        phonenumber,
                        email,
                        countylocation,
                        goodconductrefno,
                        portfoliourl,
                        profileimage: imageUrl,
                        // copyofcv: cvUrl,
                        // copyofgoodconduct: goodConductUrl,
                        // idFrontUrl: idFrontUrl,
                        // idBackUrl : idBackUrl
                    });


                })
                .catch(error => alert(error.message))

                toast.current.show({ severity: 'success', summary: 'User Registered', detail: 'User registered successfully', life: 3000 });
                navigate('/fundi');
                
        } else {
            alert("Please fill all the fields !!");
        }

    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (!user) {
                navigate('/')
            }
        })

        return unsubscribe
    }, [])

    return (
        <div className='F2Container'>
            <MainHeader />
            <Toast ref={toast}></Toast>
            <div className='F2body'>
                <div className='F2card'>
                    <h1 className='F2H1'>Register {firstname} {lastname}</h1>
                    <div className='F2input'>
                        <div className='selection2'>
                            <label className='label2' >Professional Body:</label><br />
                            <select className='F2inputsign'
                            >
                                <option value={professionalbody}>{professionalbody}</option>
                            </select>
                        </div>
                        <div className='selection2'>
                            <label className='label2' >Profession Body Registration Number:</label><br />
                            <input className='F2inputsign'
                                value={professionalbodyno}
                                onChange={(e) => setProfessionalBodyNo(e.target.value)}
                                type="text"
                                required
                            />
                        </div>
                    </div>
                    <div className='F2input'>
                        <div className='selection2'>
                            <label className='label2' >Identification Number:</label><br />
                            <input className='F2inputsign'
                                value={identificationnumber}
                                onChange={(e) => setIdentificationNumber(e.target.value)}
                                type="number"
                            />
                        </div>
                        <div className='selection2'>
                            <label className='label2' >Profession:</label><br />
                            <input className='F2inputsign'
                                value={profession}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='F2input'>
                        <div className='selection2'>
                            <label className='label2' >Primary Mpesa Phone Number:</label><br />
                            <input className='F2inputsign'
                                value={phonenumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                                type="tel"
                            />
                        </div>
                        <div className='selection2'>
                            <label className='label2' >Good Conduct Reference No:</label><br />
                            <input className='F2inputsign'
                                value={goodconductrefno}
                                onChange={(e) => setGoodConductRefNo(e.target.value)}
                                type='text'
                            />
                        </div>
                    </div>
                    <div className='F2input'>
                        <div className='selection2'>
                            <label className='label2' >Portfolio Url:</label><br />
                            <input className='F2inputsign'
                                value={portfoliourl}
                                onChange={(e) => setPortfolioUrl(e.target.value)}
                                required
                                type="text"
                            />
                        </div>
                        <div className='selection2'>
                            <label className='label2' >Email:</label><br />
                            <input className='F2inputsign'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type='email'
                            />
                        </div>
                    </div>
                    <div className='F2input'>
                        <div className='selection2'>
                            <label className='label2' >County Location:</label><br />
                            <select
                                className='F2inputsign'
                                value={countylocation}
                                onChange={handleCountyChange}
                            >
                                <option value="">Select a county</option>
                                {counties.map((county, index) => (
                                    <option key={index} value={county.county_name}>
                                        {county.county_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='selection2'>
                            <label className='label2' >Upload Picture:</label><br />
                            <input className='F2inputsign'
                                onChange={handleFileChange}
                                type='file'
                            />
                        </div>
                    </div>

                    {/*<div className='F2input'>
                        <div className='selection2'>
                            <label className='label2' >Front Side of the National ID:</label><br />
                            <input className='F2inputsign'
                                onChange={handleFrontIDChange}
                                type='file'
                                accept='image/png, image/jpeg'
                            />
                        </div>
                        <div className='selection2'>
                            <label className='label2' >Back Side of the National ID:</label><br />
                            <input className='F2inputsign'
                                onChange={handleBackIDChange}
                                type='file'
                                accept='image/png, image/jpeg'
                            />
                        </div>
                    </div>
                    <div className='F2input'>
                        <div className='selection2'>
                            <label className='label2' >Upload Copy of CV:</label><br />
                            <input className='F2inputsign'
                                onChange={handleCVChange}
                                type='file'
                                accept='.doc,.docx,.pdf,application/msword'
                            />
                        </div>
                        <div className='selection2'>
                            <label className='label2' >Upload Copy of Good Conduct:</label><br />
                            <input className='F2inputsign'
                                onChange={handleGoodConductChange}
                                type='file'
                                accept='image/png, image/jpeg, .pdf'
                            />
                        </div>
                                </div> */}

                    <div className='F2button'>
                        <button className='F2cancel'><Link style={{ textDecoration: 'none', color: 'white' }} to='/home'>Cancel</Link></button>
                        <button onClick={handleSignUp} className='F2save'>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
