// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const clientfirebaseConfig = firebase.initializeApp( {
    apiKey: "AIzaSyCNWzxw3hU1kMBkOOj0V3H1rYaYS5s8DxE",
    authDomain: "hustle-bd804.firebaseapp.com",
    projectId: "hustle-bd804",
    storageBucket: "hustle-bd804.appspot.com",
    messagingSenderId: "669860203872",
    appId: "1:669860203872:web:a194e4744fe584c2e8e205",
    measurementId: "G-5ELYQEEG1J"
},'clientfirebaseConfig');


// Initialize Cloud Firestore and get a reference to the service
export const dbcl = clientfirebaseConfig.firestore();
export const storagecl = clientfirebaseConfig.storage();
export const storageRefcl = storagecl.ref();
export const authcl = clientfirebaseConfig.auth()