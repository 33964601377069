import React, { useState } from 'react';
import logo from '../../Assets/LOGO.png'
import './Header.css'
import { Link, useLocation } from 'react-router-dom';
import { auth } from '../../Database/config';


export default function MainHeader() {

  const location = useLocation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOrder, setIsDropdownOrder] = useState(false);
  const [nestedDropdownIndex, setNestedDropdownIndex] = useState(null);

  const isHomePage = location.pathname === '/';

  const toggleDropdown = (index) => {
    if (nestedDropdownIndex === index) {
      setNestedDropdownIndex(null);
    } else {
      setNestedDropdownIndex(index);
    }
  };
  const handleSignOut = (e) => {
    e.preventDefault();

    auth
      .signOut()
      .then(() => {
        window.location.href = '/';
      })
      .catch(error => alert(error.message))
  }


  return (
    <div className='navigationBar' >
      <Link to='/home'>
        <img src={logo} alt="My Image" style={{
          maxWidth: '100%', height: '70%', cursor: 'pointer', paddingLeft: '50px', margin: '10px',
          '@media (max-width: 768px)': {
            maxWidth: '100%',
            height: 'auto',
            paddingLeft: '10px', // Adjust the padding for smaller screens
            margin: "0px"
          },
        }} />
      </Link>

      {!isHomePage && (
        <div className='navigationButtons'>
          <nav>
            <div className={`dropdown ${isDropdownOpen ? 'open' : ''}`}>
              <a href="#" className="dropdown-trigger" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                Employees
              </a>
              {isDropdownOpen && (
                <div className="dropdown-content mt-2">
                  <ul>
                    <li>
                      <a href="#" onClick={() => toggleDropdown(0)}>Register</a>
                      {nestedDropdownIndex === 0 && (
                        <div className="nested-dropdown-content -mt-4">
                          <ul>
                            <Link to='/fundisignup'> <li>Field Agents</li></Link>
                            <Link to='/dotdotsignup' ><li>DotDot</li></Link>
                            <Link to='/materialsignup' ><li>Hardware</li></Link>
                            <Link to='/rentalsignup' ><li>Find Rental</li></Link>
                          </ul>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>

          <nav>
            <div className={`dropdown ${isDropdownOrder ? 'open' : ''}`}>
              <a href="/fundiorders" className="dropdown-trigger" onClick={() => setIsDropdownOrder(!isDropdownOrder)}>
                Orders
              </a>
            </div>
          </nav>
          <nav>
            <a href="/admins"> Admins</a>
          </nav>
          <nav>
            <a href="/payments">Payments</a>
          </nav>

          <input className='py-1 px-2 font-normal rounded text-black text-base' type="search" placeholder="Search a Fundi" />

          <nav>
            <button
              className='text-white bg-red px-4 py-1'
              style={{
                color: "white",
                fontFamily: "Lexend",
                fontSize: "15px",
                border: "none",
                borderRadius: '5px',
                background: 'red',
                height: '35px',
                cursor: 'pointer'
              }}
              onClick={handleSignOut}
            >Sign Out</button>
          </nav>

        </div>
      )}
    </div>
  )
}