import React, { useEffect, useState } from 'react';
import MainHeader from '../../Components/Header/Header';
import { auth } from '../../Database/config';
import { useNavigate } from 'react-router-dom';
import '../Agents/Fundi.css';
import { dbcl } from '../../Database/client';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


export default function Fundi() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/')
      }
    })

    return unsubscribe
  }, [])

  function getAmount(amount) {
    const fixedAmount = amount.toFixed(2);
    return `Ksh. ${fixedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  function getJobLocation(lat,long) {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
    return (
      <a href={mapsUrl} target='_blank' className='text-sm font-semibold hover:underline text-blue-600'>Open Maps</a>
    );
  }

  function getImageUrl(url) {
    return(
      <a href={url} target='_blank' className='text-sm font-semibold hover:underline text-blue-600'>View Image</a>
    );
  }

  useEffect(() => {
    const collectionRef = dbcl.collection('ClientIssues');
    collectionRef.get().then((querySnapshot) => {
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });
      setData(fetchedData);
      setLoading(false);
    });
  }, []);

  const header = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-2">
        <div>
          <h1 className='font-bold text-xl'>
            <span style={{ color: "#FF0000" }}>Client</span> Orders ({data.length})
          </h1>
        </div>
      </div>
    );
  };


  return (
    <div className='flex flex-col gap-12'>
      <MainHeader />
      <div className='flex flex-col gap-4 mt-24 px-12 pb-20'>
        <DataTable className='text-sm'
          value={data}
          stripedRows
          paginator
          header={header}
          rows={10}
          emptyMessage="No orders found."
          loading={loading}
          rowsPerPageOptions={[10, 25, 50]}
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column className='border' header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1}></Column>
          <Column className='border' header="Client Name" body={(rowData) => `${rowData.clientFirstName} ${rowData.clientLastName}`}></Column>
          <Column className='border' header="Agent Name" body={(rowData) => `${rowData.AgentFirstName} ${rowData.AgentLastName} `}></Column>
          <Column className='border' header="Agent Phone Number" body={(rowData) => `${rowData.AgentPhoneNumber}  `}></Column>
          <Column className='border' header="OrderId" body={(rowData) => `${rowData.id}`}></Column>
          <Column className='border' header="Category" body={(rowData) => `${rowData.selected}`}></Column>
          {/* <Column className='border' header="Status" body={(rowData) => `${rowData.status}`}></Column> */}
          <Column className='border' header="Location" body={(rowData) => getJobLocation(rowData.location.latitude, rowData.location.longitude) }></Column>
          <Column className='border' header="Job Image" body={(rowData) => getImageUrl(rowData.imageUrl) }></Column>
          <Column className='border' header="Description" body={(rowData) => `${rowData.description}` }></Column>
          <Column className='border' header="Amount" body={(rowData) => {return rowData.chosenPrice ? getAmount(rowData.chosenPrice) : getAmount(rowData.amount);}}></Column>
          {/* <Column className='border' header="Promo Code Value" body={(rowData) => `Ksh ${rowData.promoCodeValue}`}></Column> */}
        </DataTable>
      </div>
    </div>
  )
}

{/* <div className='fundiOrderBody'>

<div className='fundiOrderTitle'>
  <h1>
    <span style={{ color: "#FF0000" }}>R-Fundi</span> Agents
  </h1>
</div>

<div className='fundiOrderTable'>
  <table className='Ftable'>
    <thead className='FtHead'>
      <tr className='FtRow'>
        <th className='th'>Order ID</th>
        <th className='th' >Agent Names</th>
        <th className='th'>Agent Phone Number</th>
        <th className='th'>Client Name</th>
        <th className='th'>Client Phone Number</th>
        <th className='th'>Description</th>
        <th className='th'>Category</th>
        <th className='th'>Amount</th>
        <th className='th'>Status</th>
      </tr>
    </thead>
    <tbody className='FtBody'>
      {data.map((item, index) => (
        <tr key={item.id}>
          <td className='td'>{item.id}</td>
          <td className='td'>{item.AgentFirstName} {item.AgentLastName}</td>
          <td className='td'>{item.AgentPhoneNumber}</td>
          <td className='td'>{item.clientName}</td>
          <td className='td'>{item.clientNumber}</td>
          <td className='td'>{item.description}</td>
          <td className='td'>{item.selected}</td>
          <td className='td'>{item.amount}</td>
          <td className='td'>{item.status}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

</div> */}
