import React, { useEffect } from 'react'
import Fundi from '../../Assets/fundi.png'
import './HomeScreen.css'
import MainHeader from '../../Components/Header/Header'
import { auth } from '../../Database/config'
import { Link, useNavigate } from 'react-router-dom'

export default function HomeScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/')
      }
    })

    return unsubscribe
  }, [navigate])
  return (
    <div className='Homescreen'>
      <MainHeader />
      <div className='homescreenBody'>

        <div className='flex flex-row items-center justify-center gap-12'>

            <img style={{ width: '181px', height: '100%' }} src={Fundi} alt='fundi' />

          <div className='flex flex-col gap-4'>
            <h1 className='infoTitle'>Welcome to Hustle Kenya Admin portal</h1>
            <p className='infoDetails'>Here you can do the following Functions:
              <br />
              1.Disable/Delete Agents
              <br />
              2.Verify Agents
              <br />
              3.Confirm Payments
              <br />
              4.Check Pending or Completed tasks
              <br />
              5.Manage Revenue and Expenses</p>
          </div>
        </div>

        <div className='Apps px-12'>
          <Link to='/fundi' style={{ textDecoration: "none", color: 'black', fontSize: '15' }}>
            <div className='appCard flex flex-col gap-4 py-2'>
              <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
              <h2 className='appLabel'>
                Agents
              </h2>
            </div>
          </Link>
          
          <Link to='/hardware' style={{ textDecoration: "none", color: 'black', fontSize: '15' }}>
            <div className='appCard flex flex-col gap-4 py-2'>
              <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
              <h2 className='appLabel'> Clients</h2>
            </div>
          </Link>

{/*
          <div className='appCard flex flex-col gap-4 py-2'>
            <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
            <h2 className='appLabel'> DotDot</h2>
          </div>

          <div className='appCard flex flex-col gap-4 py-2'>
            <img style={{ width: '64px', height: '64' }} className='Fundi' src={Fundi} alt='fundi' />
            <h2 className='appLabel'> Find Property</h2>
          </div>
          */}

        </div>

      </div>

    </div>
  )
}
