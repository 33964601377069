import React, { useState, useEffect } from 'react';
import './Login.css'
import { useNavigate } from 'react-router-dom';
import MainHeader from '../../Components/Header/Header';
import { auth } from '../../Database/config';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleLogin = () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(userCredentials => {
        const user = userCredentials.user;
        console.log('Logged in with:', user.email);
      })
      .catch(error => alert(error.message))
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        navigate('/home')
      }
    })

    return unsubscribe
  }, [])

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className='login'>
      <MainHeader />

      <div className='loginBody'>
        <div className='loginTitle'>
          <h1 className='text-3xl'>Welcome to Hustle Kenya Admin</h1>
        </div>
        <div className='loginFormCard sm:w-1/3 w-full'>
          <h1 className='py-2 font-semibold'>Sign In</h1>
          <form className='flex flex-col items-start gap-2 w-full bg-gray-100 shadow rounded p-6'>
            <label>Email Address</label>
            <input className='w-full bg-gray-200 text-black p-2 rounded border border-gray-600'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email Address' />
            <div className='flex items-start gap-2 flex-col w-full'>
              <label>Password</label>
              <div className='flex items-center w-full'>
                <input className='flex-none w-11/12 bg-gray-200 text-black p-2 rounded border border-gray-600'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='password'
                  type={showPassword ? 'text' : 'password'}
                />
                <span
                  className='cursor-pointer flex-none w-1/12'
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? '👁️' : '👁️‍🗨️'}
                </span>
              </div>
            </div>
          </form>

          <button onClick={handleLogin} className='w-full bg-[#17304a] text-white rounded mt-4 py-2 hover:shadow'>Sign In</button>

        </div>
      </div>


    </div>
  )
}
