import React, { useEffect,useRef, useState } from 'react';
import MainHeader from '../../Components/Header/Header';
import { auth, db } from '../../Database/config';
import { useNavigate } from 'react-router-dom';
import '../Agents/Fundi.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { EyeIcon, PencilIcon, TrashIcon, CheckCircleIcon } from '@heroicons/react/24/solid';


export default function Admins() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (!user) {
        navigate('/')
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const collectionRef = db.collection('Admins');

    collectionRef.get().then((querySnapshot) => {
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });
      setData(fetchedData);
      setLoading(false);
    });
  }, []);

  const showButtons = (rowData) => {
    return (
      <div className='flex flex-row justify-between'>
        <EyeIcon onClick={() => redirectToView(rowData.id)} className='w-4 h-4 text-gray-500 cursor-pointer' />
        <PencilIcon onClick={() => redirectToUpdate(rowData.id)} className='w-4 h-4 text-green-500 cursor-pointer' />
        <TrashIcon onClick={() => confirm(rowData.id)} className='w-4 h-4 text-red-500 cursor-pointer' />
      </div>
    );
  };

  function showPic(url) {
    return (
      <a className='text-blue-600 underline' href={url} target='_blank'>View Picture</a>
    );
  }

  const redirectToUpdate = (id) => {
    // navigate(`/fundi/update/${id}`);
  }

  const redirectToView = (id) => {
    // navigate(`/fundi/${id}`);
  }

  const deleteAgent = async (id) => {
    // try {
    //   await dbc.collection('FundiAppUsers').doc(id).delete();
    //   const updatedData = data.filter((item) => item.id !== id);
    //   setData(updatedData);
    //   toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have deleted user successfully', life: 3000 });
    // } catch (error) {
    //   toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error occurred. Please try again later.', life: 3000 });
    // }
  };

  const confirm = (id) => {
    confirmDialog({
      message: 'Are you sure you want to proceed? Once deleted, the record cannot be recovered',
      header: 'Delete Record',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      acceptClassName: 'bg-red-500 px-2 py-1 text-white font-semibold ml-4',
      rejectClassName: 'bg-gray-300 px-2 py-1 text-black font-semibold',
      acceptLabel: 'Yes, Delete',
      rejectLabel: 'Cancel',
      accept: () => deleteAgent(id),
      reject
    });
  }

  function showStatus(status) {
    if(status) {
      return (
        <div className='text-white gap-1 bg-green-600 text-center rounded w-1/2 p-1 text-xs'>Active</div>
      );
    } else {
      return (
        <div className='text-white bg-red-600 text-center rounded w-1/2 p-1 text-xs'>Inactive</div>
      );
    }
  }

  const reject = () => {
    toast.current.show({ severity: 'info', summary: 'Action cancelled', detail: 'You have not deleted user record', life: 3000 });
  }
  const renderHeader = () => {
    return (
      <div className="flex flex-row items-center justify-between w-full gap-2">
        <div>
          <h1 className='font-bold text-xl'>
            <span style={{ color: "#FF0000" }}>R-Fundi</span> Admins ({data.length})
          </h1>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <div className='flex flex-col'>
      <Toast ref={toast}></Toast>
      <ConfirmDialog />
      <MainHeader className="mb-20" />
      <div className='flex flex-col gap-4 mt-24 px-12 pb-20'>
        <DataTable className='text-sm'
          value={data}
          stripedRows
          paginator
          globalFilterFields={['name']}
          header={header}
          rows={10}
          emptyMessage="No agents found."
          loading={loading}
          rowsPerPageOptions={[10, 25, 50]}
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column className='border' header="#" body={(rowData, rowIndex) => rowIndex.rowIndex + 1}></Column>
          <Column className='border' field='name' header="Name" body={(rowData) => `${rowData.Username}`}></Column>
          <Column className='border' header="Role" body={(rowData) => `${rowData.Role}`}></Column>
          <Column className='border' header="ID Number" body={(rowData) => `${rowData.Identification}`}></Column>
          <Column className='border text-center' header="Profile Picture" body={(rowData) => showPic(rowData.profilePic)}></Column>
          <Column className='border text-center' header="Status" body={(rowData) => showStatus(rowData.Status) }></Column>
          <Column className='border' header="Actions" body={showButtons}></Column>
        </DataTable>
      </div>
    </div>
  )
}


{/* <div className='fundiOrderBody'>

<div className='fundiOrderTitle'>
  <h1>
    <span style={{ color: "#FF0000" }}>Rekebisha Admins</span> Agents
  </h1>
</div>

<div className='fundiOrderTable'>
  <table className='Ftable'>
    <thead className='FtHead'>
      <tr className='FtRow'>
        <th className='th'>Number</th>
        <th className='th' >Full Names</th>
        <th className='th'>ID Number</th>
        <th className='th'>Role</th>
        <th className='th'>Profile Link</th>
        <th className='th'>Profile Pic</th>
        <th className='th'>Action</th>
      </tr>
    </thead>
    <tbody className='FtBody'>
      {data.map((item, index) => (
        <tr key={item.id}>
          <td className='td'>{index + 1}</td>
          <td className='td'>{item.Username} </td>
          <td className='td'>{item.Identification}</td>
          <td className='td'>{item.Role}</td>
          <td className='td'><a href={item.profileLink}> {item.profileLink}</a></td>
          <td className='td'><a href={item.profilePic}>{item.profilePic}</a></td>
          <td className='td'><button className='TButton'>Printed</button></td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

</div> */}