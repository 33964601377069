import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Screens/Login/Login'
import HomeScreen from './Screens/HomeScreen/HomeScreen'
import FundiOrder from './Screens/Orders/FundiOrder';
import FundiSignup from './Screens/FundiSignup/FundiSignup';
import FundiSignup2 from './Screens/FundiSignup/FundiSignup2/FundiSignup2';
import DotDotsignup from './Screens/DotDotSignUp/DotDotsignup';
import Fundi from './Screens/Agents/Fundi';
import MaterialSignup from './Screens/MaterialSignup/MaterialSignup';
import Hardware from './Screens/Agents/Hardware/Hardware.jsx';
import RentalSignup from './Screens/FindRental/FindRental';
import Admins from "./Screens/AdminsDetails/Admins"
import Receipt from './Screens/RecieptGenerator.js/RecieptGenerator';
import FundiUpdate from './Screens/FundiUpdate/FundiUpdate';
import FundiView from './Screens/FundiView/FundiView';
import Payments from './Screens/Payments/Payments.jsx';

import "primereact/resources/themes/lara-light-cyan/theme.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/fundiorders" element={<FundiOrder />} />
        <Route path="/fundisignup" element={<FundiSignup />} />
        <Route path="/fundisignup2" element={<FundiSignup2 />} />
        <Route path="/dotdotsignup" element={<DotDotsignup />} />
        <Route path="/materialsignup" element={<MaterialSignup />} />
        <Route path="/fundi" element={<Fundi />} />
        <Route path="/hardware" element={<Hardware />} />
        <Route path="/rentalsignup" element={<RentalSignup />} />
        <Route path="/admins" element={<Admins />} />
        <Route path="/receipt" element={<Receipt />} />
        <Route path="/fundi/update/:id" element={<FundiUpdate />}></Route>
        <Route path="/fundi/:id" element={<FundiView />}></Route>
        <Route path="/payments" element={<Payments />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
