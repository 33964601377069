// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const otherfirebaseConfig = firebase.initializeApp( {
    apiKey: "AIzaSyA8B6XlBg06vuwUT3nTvURamt3RFiQT-C8",
    authDomain: "hustleagent-35130.firebaseapp.com",
    projectId: "hustleagent-35130",
    storageBucket: "hustleagent-35130.appspot.com",
    messagingSenderId: "70124669345",
    appId: "1:70124669345:web:0bf740df01ec526230e3e0",
    measurementId: "G-RND862L0E6"
},'otherfirebaseConfig');


// Initialize Cloud Firestore and get a reference to the service
export const dbc = otherfirebaseConfig.firestore();
export const storagec = otherfirebaseConfig.storage();
export const storageRefc = storagec.ref();
export const authc = otherfirebaseConfig.auth()