import React, { useEffect, useState } from 'react';
import './FundiUpdate.css';
import MainHeader from '../../Components/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../../Database/config';
import { dbc } from '../../Database/fundi';
import countiesData from '../../data/county.json';

export default function FundiUpdate() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [data, setData] = useState({});
    const [fullname, setFullName] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [profession, setProfession] = useState("");
    const [professionalbody, setProfessionalBody] = useState("");
    const [professionalbodyno, setProfessionalBodyNo] = useState("");
    const [identificationnumber, setIdentificationNumber] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState('');
    const [countylocation, setCountyLocation] = useState("");
    const [goodconductrefno, setGoodConductRefNo] = useState("");
    const [portfoliourl, setPortfolioUrl] = useState("");
    const [counties, setCounties] = useState([]);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [idError, setIdError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [professionError, setProfessionError] = useState(false);
    const [portfolioUrlError, setPortfolioUrlError] = useState(false);
    const [countyLocationError, setCountyLocationError] = useState(false);

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (!user) {
                navigate('/');
            }
        });

        setCounties(countiesData.County);

        const fetchUserData = async () => {
            try {
                const docSnapshot = await dbc.collection('users').doc(id).get();

                if (docSnapshot.exists) {
                    const userData = docSnapshot.data();
                    setData(userData);
                    setFullName(`${userData.firstName} ${userData.lastName}`);
                    setFirstName(userData.firstName || "");
                    setLastName(userData.lastName || "");
                    setProfession(userData.profession || "");
                    setProfessionalBody(userData.professionalbody || "");
                    setProfessionalBodyNo(userData.professionalbodyno || "");
                    setIdentificationNumber(userData.idNumber || "");
                    setPhoneNumber(userData.phoneNumber || "");
                    setEmail(userData.email || "");
                    setCountyLocation(userData.countylocation || "");
                    setGoodConductRefNo(userData.goodconductrefno || "");
                    setCounties(userData.counties || "");
                    setPortfolioUrl(userData.portfoliourl || "");
                } else {
                    alert('Error fetching user data! Try again later.')
                }
            } catch (error) {
                console.error("Error getting document:", error);
            }
        };

        fetchUserData();

    }, [id, navigate]);

    const handleUpdate = async () => {
        setFirstNameError(!data.firstname);
        setLastNameError(!data.lastname);
        setIdError(!identificationnumber);
        setPhoneError(!phonenumber.match(/^(07|01)\d{8}$/));
        setPortfolioUrlError(!portfoliourl);
        setCountyLocationError(!countylocation);
        setProfessionError(!profession);

        if (firstname && lastname && profession && phonenumber && portfoliourl && countylocation) {
            try {
                await dbc.collection('FundiAppUsers').doc(id).update({
                    firstname,
                    lastname,
                    profession,
                    professionalbody,
                    professionalbodyno,
                    identificationnumber,
                    phonenumber,
                    email,
                    portfoliourl,
                    countylocation,
                    goodconductrefno,
                    counties,
                });

                navigate('/fundi');

            } catch (error) {
                alert("Error updating user details:", error);
            }
        }

    };

    return (
        <div className='Fcontainer'>
            <MainHeader />
            <div className='FsignupBody'>
                <h1 className='FsignupH1'>Update: {fullname} Profile</h1>
                <div className='FsignupCard'>
                    <div className='Finput1'>
                        <div className='InputContainer'>
                            <input
                                className='Finput'
                                placeholder='First Name'
                                value={firstname}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            {firstNameError && <div className='ErrorMessage'>First Name Required</div>}
                        </div>

                        <div className='InputContainer'>
                            <input
                                className='Finput'
                                placeholder='Last Name'
                                value={lastname}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            {lastNameError && <div className='ErrorMessage'>Last Name Required</div>}
                        </div>
                    </div>
                    <div className='Finput1'>
                        <div className='selection'>
                            <label className='label' >Profession:</label><br />
                            <select className='Finput'
                                value={profession}
                                onChange={(e) => setProfession(e.target.value)}
                                required
                            >
                                <option value="">-- Select --</option>
                                <option>Key Cutting, Locks and Doors</option>
                                <option>Interior Designer</option>
                                <option>General Builder(Cost Estimator)</option>
                                <option>Painter</option>
                                <option>Household Electronics Expert</option>
                                <option>Fumigation Expert</option>
                                <option>Electrician and Electrical fittings</option>
                                <option>Plumber and Sanitary Fittings</option>
                                <option>Glass/ Mirror/ Window fixer</option>
                                <option>Aluminium and Fit-outs</option>
                                <option>Carpenter</option>
                                <option>Tiles - Ceramic, Granite</option>
                                <option>Gypsum Expert</option>
                                <option>Roofer</option>
                                <option>Mazeras/ Cladding</option>
                                <option>Metal Expert - Welder</option>
                                <option>Locks and Doors</option>
                                <option>Waterproofing Expert</option>
                                <option>External Paver</option>
                                <option>Terrazzo</option>
                                <option>Landscaper</option>
                                <option>Parking Shades</option>
                                <option>Window Blinds, Curtains, Boxes, Rods, Films and Tints</option>
                                <option>Advertising Signages and Boxes</option>
                                <option>Carpet and Upholstery</option>
                                <option>Furniture and Fittings</option>
                                <option>Plastic Pipes and Fittings</option>
                                <option>Concrete Pipes and Culverts</option>
                            </select>
                            {professionError && <div className='ErrorMessage'>Profession required</div>}
                        </div>
                        <div className='selection'>
                            <label className='label' >Profession Body:</label><br />
                            <select className='Finput'
                                value={professionalbody}
                                onChange={(e) => setProfessionalBody(e.target.value)}
                                required
                            >
                                <option value="">-- Select --</option>
                                <option>Police Clearance Certificate: Registered Public Servant</option>
                                <option>NCA: Registered Construction Professional</option>
                                <option>KMPDC: Registered Medical Practitioner</option>
                                <option>NCK: Nurses Council Kenya</option>
                                <option>EARB: Estate Agent</option>
                                <option>ISK: Estate Agent</option>
                                <option>VRB: Estate Agent</option>
                                <option>ERB: Engineers</option>
                                <option>BORAQS: Architect/ Quantity Surveyor</option>
                                <option>KBCTFIEU: Registered Construction Union</option>
                                <option>KNFJKA: Jua Kali Workers</option>
                            </select>
                            {false && <div className='ErrorMessage'></div>}
                        </div>
                    </div>
                    <div className='Finput1'>
                        <div className='selection'>
                            <label className='label'>Profession Body Registration Number:</label><br />
                            <input className='Finput'
                                value={professionalbodyno}
                                onChange={(e) => setProfessionalBodyNo(e.target.value)}
                                type="text"
                                required
                            />
                            {false && <div className='ErrorMessage'></div>}
                        </div>
                        <div className='selection'>
                            <label className='label'>Identification Number:</label><br />
                            <input className='Finput'
                                value={identificationnumber}
                                onChange={(e) => setIdentificationNumber(e.target.value)}
                                type="number"
                            />
                            {idError && <div className='ErrorMessage'>National ID is required</div>}
                        </div>
                    </div>
                    <div className='Finput1'>
                        <div className='selection'>
                            <label className='label'>Primary Mpesa Phone Number:</label><br />
                            <input className='Finput'
                                value={phonenumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                type="text"
                                required
                            />
                            {phoneError && <div className='ErrorMessage'>Enter a valid phone number</div>}
                        </div>
                        <div className='selection'>
                            <label className='label'>Good Conduct Reference No:</label><br />
                            <input className='Finput'
                                value={goodconductrefno}
                                onChange={(e) => setGoodConductRefNo(e.target.value)}
                                type="number"
                            />
                            {false && <div className='ErrorMessage'></div>}
                        </div>
                    </div>
                    <div className='Finput1'>
                        <div className='selection'>
                            <label className='label'>Portfolio URL:</label><br />
                            <input className='Finput'
                                value={portfoliourl}
                                onChange={(e) => setPortfolioUrl(e.target.value)}
                                type="text"
                                required
                            />
                            {portfolioUrlError && <div className='ErrorMessage'>Portfolio URL is required</div>}
                        </div>
                        <div className='selection'>
                            <label className='label'>County Location:</label><br />
                            <select
                                className='F2inputsign'
                                value={countylocation}
                                onChange={(e) => setCountyLocation(e.target.value)}
                            >
                                <option value="">Select a county</option>
                                {countiesData.County.map((county, index) => (
                                    <option key={index} value={county.county_name}>
                                        {county.county_name}
                                    </option>
                                ))}
                            </select>
                            {countyLocationError && <div className='ErrorMessage'>Select county location</div>}
                        </div>
                    </div>
                    <div className='Finput1'>

                    </div>
                    <button className="Fbutton1" onClick={handleUpdate}>Update Details</button>
                </div>
            </div>
        </div>
    );
}
