import React, {useEffect, useState} from 'react';
import './FundiSignup.css';
import MainHeader from '../../Components/Header/Header';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../Database/config';


export default function FundiSignup() {
    const navigate = useNavigate();

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [profession, setProfession] = useState("");
    const [professionalbody, setProfessionalBody] = useState("");

    const start = (e) =>{
        e.preventDefault();
        navigate('/fundisignup2', { state: { firstname: firstname, lastname:lastname, profession: profession, professionalbody: professionalbody } });  

    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (!user) {
                navigate('/')
            }
        })
      
        return unsubscribe
      }, [])

  return (
    <div className='Fcontainer'>
        <MainHeader/>
        <div className='FsignupBody'>
            <div className='FsignupCard'>
                <h1 className='FsignupH1'>Let's get you started</h1>
                <div className='Finput1'>
                    <input className='Finput' 
                    placeholder='First Name'
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    />
                    <input className='Finput' 
                    placeholder='Last Name'
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    
                    />
                </div>
                <div className='Finput1'>
                    <div className='selection'>
                        <label className='label' >Profession:</label><br/>
                        <select className='Finput'
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        required
                        >
                        <option value="">-- Select --</option>
                        <option>Sales and Marketing</option>
                        </select>
                    </div>
                    <div className='selection'>
                        <label className='label' >Type of employment:</label><br/>
                        <select className='Finput'
                         value={professionalbody}
                         onChange={(e) => setProfessionalBody(e.target.value)}
                         required
                        >
                        <option value="">-- Select --</option>
                        <option>Temporary</option>
                        <option>Permanent</option>
                        
                        </select>
                    </div>
                </div>

                <button onClick={start} className='Fbutton1'>Continue</button>
                
            </div>
        </div>
    </div>
  )
}
