import React, { useEffect, useState } from 'react';
import MainHeader from '../../Components/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../../Database/config';
import { dbc } from '../../Database/fundi';

export default function FundiView() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [data, setData] = useState({});

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (!user) {
                navigate('/');
            }
        });

        const fetchUserData = async () => {
            try {
                const docSnapshot = await dbc.collection('users').doc(id).get();

                if (docSnapshot.exists) {
                    const userData = docSnapshot.data();
                    setData(userData);
                } else {
                    alert('Error fetching user data! Try again later.')
                }
            } catch (error) {
                console.error("Error getting document:", error);
            }
        };

        fetchUserData();

    }, [id, navigate]);


    return (
        <div className='Fcontainer'>
            <MainHeader />
            <div className='flex flex-col justify-center items-center gap-2 px-20 py-28 w-full'>
                <img src={data.profileImageURL} className='w-48 h-48 rounded-full'></img>
                <h2 className='text-gray-700 font-semibold text-2xl'>{`${data.firstName}  ${data.lastName}`}</h2>
                <div className='bg-red-600 rounded text-white py-1 px-2 text-xs font-semibold'>{data.profession}</div>
                <div className='flex flex-col gap-4 items-center mt-6 text-gray-700'>
                    <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Email:</div>
                        <div className='text-base font-semibold'>{data.email}</div>
                    </div>
                    <div className='border-b border-gray-200 w-full'/>
                    <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Phone:</div>
                        <div className='text-base font-semibold'>{data.phoneNumber}</div>
                    </div>
                    <div className='border-b border-gray-200 w-full'/>
                    <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>National ID:</div>
                        <div className='text-base font-semibold'>{data.idNumber}</div>
                    </div>
                    <div className='border-b border-gray-200 w-full'/>
                    <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Good Conduct Ref:</div>
                        <div className='text-base font-semibold capitalize'>{data.goodconductrefno}</div>
                    </div>
                    
                    <div className='border-b border-gray-200 w-full'/>
                    <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Profile Photo:</div>
                        <a href={data.profileImageURL} target='_blank' className='text-blue-500 underline text-base font-semibold'>Click to open image</a>
                    </div>
                    {data.frontIdImageURL && <div className='border-b border-gray-200 w-full'/>}
                    {data.frontIdImageURL && <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Front of National ID:</div>
                        <a href={data.frontIdImageURL } target='_blank' className='text-blue-500 underline text-base font-semibold'>Click to open image</a>
                    </div>}
                    {data.backIdImageURL && <div className='border-b border-gray-200 w-full'/>}
                    {data.backIdImageURL && <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Back of National ID:</div>
                        <a href={data.backIdImageURL} target='_blank' className='text-blue-500 underline text-base font-semibold'>Click to open image</a>
                    </div>}
                    {data.copyofcv && <div className='border-b border-gray-200 w-full'/>}
                    {data.copyofcv && <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Download CV:</div>
                        <a href={data.copyofcv} target='_blank' className='text-blue-500 underline text-base font-semibold'>Dowload Resume</a>
                    </div>}
                    {data.copyofgoodconduct && <div className='border-b border-gray-200 w-full'/>}
                    {data.copyofgoodconduct && <div className='flex flex-row items-center gap-2'>
                        <div className='text-base font-bold'>Good Conduct:</div>
                        <a href={data.copyofgoodconduct} target='_blank' className='text-blue-500 underline text-base font-semibold'>View Good Conduct</a>
                    </div>}
                </div>
            </div>
        </div>
    );
}
